iframe{
  height: 100%;
  border:none;
  padding: 0px 5px;
  opacity: 0;
  visibility: hidden;
  transition: ease-in-out 1.5s;
  position: absolute;
  top: 0px;
}

@media screen and (min-width: 2000px) {
  iframe{
    height: 100%;
    top: 40px;
  }
}

html, body{
  background-color: #181818;
  padding: 0px;
  margin: 0px;
  overflow-y: hidden;
  overflow-x: hidden;
}

.active{
  opacity: 1;
  visibility: initial;
}

.iframes{
  transition: 0.5s all;
}